import delegate from '../../libs/event-delegator/event-delegator.js';

var internals = {};

internals.toggleEvents = function(tag){
    let eventObjs = document.querySelectorAll('.event'),
        l = eventObjs.length;
    //Toggle the hidden class
    for (var i = 0, obj = eventObjs[0]; i < l; obj = eventObjs[++i])
    {
        if (obj.classList.contains(tag)){
            obj.classList.remove('hidden');
        } else {
            obj.classList.add('hidden');
        }
    }
};

internals.togglePageNav = function(tag){
    let links = document.querySelectorAll('.page-nav a'),
        l = links.length,
        DOM = document.querySelector('.page-nav a[href="#' + tag + '"]');
    for (var i = 0, obj = links[0]; i < l; obj = links[++i])
    {
        obj.classList.remove('selected');
    }
    if (DOM){
        DOM.classList.add('selected');
    }
};

internals.init = function(){
    if (document.querySelectorAll('.events')){
        //If landing on page with a hash
        let currHash = window.location.hash;
        if (currHash !== '') {
            let tag = currHash.substring(1).toLowerCase();
            internals.toggleEvents(tag);
            internals.togglePageNav(tag);
        }
        delegate('.page-nav', 'a', 'click', (event) => {
            let tag = event.target.hash.substring(1).toLowerCase();
            if (!tag || tag === ''){
                internals.toggleEvents('event');
            } else {
                internals.toggleEvents(tag);
            }
            internals.togglePageNav(tag);
        });
    }
};

module.exports.init = internals.init;