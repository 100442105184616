export default function resultsLoader() {

	if (document.querySelector('.header') === null) {
		return;
	}

	var searchBar = document.querySelector('.header__search');

	if (searchBar) {
		var input = searchBar.querySelector('input'),
			searchButton = searchBar.querySelector('.search'),
			nav = document.getElementById('site-nav'),
			list = nav.querySelector('.list');

		var openSearch = (event) => {
				if (nav) {
					var width = nav.getBoundingClientRect().width + 'px';

					searchBar.classList.add('open');
					nav.classList.remove('open');

					if (searchBar.classList.contains('open')) {
						// Keep the search from being submitted blank
						event.preventDefault();
						event.stopPropagation();

						// Put focus on search-bar
						input.focus();

						// Needed for search animation
						list.style.width = width;

						// Swap accessibility titles
						var temp = searchButton.getAttribute("alternate-label");
						searchButton.setAttribute("alternate-label", searchButton.getAttribute("aria-label"))
						searchButton.setAttribute("aria-label", temp)

						// Add click listener for whole page
						document.addEventListener('click', closeSearch);
						// Remove original listener so that the search can be submitted
						searchButton.removeEventListener('click', openSearch);
					}
				}
			},

			closeSearch = (event) => { //jshint ignore:line
				// Close search-bar if any element but the search-bar or the search icon is clicked
				if (event.target !== input && event.target !== searchButton) {
					searchBar.classList.remove('open');

					// Delayed for the animation
					list.removeAttribute('style');

					// Swap accessibility titles
					var temp = searchButton.getAttribute("alternate-label");
					searchButton.setAttribute("alternate-label", searchButton.getAttribute("aria-label"))
					searchButton.setAttribute("aria-label", temp)

					// Removes the listener so that this isn't fired each time the page is clicked
					document.removeEventListener('click', closeSearch);

					// Restores the original listener
					searchButton.addEventListener('click', openSearch);
				}
			};

		searchButton.addEventListener('click', openSearch);
	}
}
