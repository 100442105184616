export default function swipe({
    up = null,
    down = null,
    left = null,
    right = null
} = {}) {
    var xDown = null,
        yDown = null;

    var handleTouchStart = (event) => {
            xDown = event.touches[0].clientX;
            yDown = event.touches[0].clientY;
        },

        handleTouchMove = (event) => {
            if (!xDown || !yDown) {
                return;
            }

            var xUp = event.touches[0].clientX,
                yUp = event.touches[0].clientY,
                xDiff = xDown - xUp,
                yDiff = yDown - yUp;

            if (Math.abs(xDiff) > Math.abs(yDiff) ) {
                if (xDiff > 0 && left) {
                    left(event);
                } else if (right) {
                    right(event);
                }
            } else {
                if (yDiff > 0 && up) {
                    up(event);
                } else if (down) { 
                    down(event);
                }
            }
            /* reset values */
            xDown = null;
            yDown = null;
        };

    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchmove', handleTouchMove, false);
}