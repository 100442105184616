
module.exports = function(property, value){
    var el = document.createElement('div'),
    prefixes = 'Webkit Moz O ms Khtml'.split(' ');

    // Use inherit if no value is supplied (inherit is always supported)
    value = arguments.length === 2 ? value : 'inherit';

    // Change to camelCase
    property = property.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });

    // Check unprefixed property first
    if (property in el.style) {
        
        // Check value if property is supported
        el.style[property] = value;
        return el.style[property] ? true : false;
    }

    var i = prefixes.length,
        prefixedProperty = '';

    // Check against all possible prefixes
    while(i--) {
        prefixedProperty = prefixes[i] + property[0].toUpperCase() + property.substr(1);
        if (prefixedProperty in el.style) {

            // check value if prefixed property is supported
            el.style[prefixedProperty] = value;
            return el.style[prefixedProperty] ? true : false;
        } 
    }

    return false;
};