
// IMPORTS



// INTERNALS

var internals = {};

internals.getNodes = function() {
	internals.nav = document.querySelector('.primer-offscreen-nav__nav');
	internals.overlay = document.querySelector('.primer-offscreen-nav__overlay');
	internals.hamburger = document.querySelector('.primer-offscreen-nav__hamburger');
	internals.navToggleNode = document.querySelector('.primer-offscreen-nav__nav-toggle');
};

internals.setupListeners = function() {
	internals.navToggleNode.addEventListener('click', internals.toggleNav);
	internals.overlay.addEventListener('click', internals.toggleNav);
};

internals.toggleNav = function() {
	internals.nav.classList.toggle('primer-offscreen-nav__nav--active');
	internals.overlay.classList.toggle('primer-offscreen-nav__overlay--active');
	internals.hamburger.classList.toggle('primer-offscreen-nav__hamburger--active');
};

internals.init = function() {
	
	// If component doesn't exist on current page, don't run the componet's JS
    if(document.querySelectorAll('.primer-offscreen-nav__nav').length === 0) {
    	return false;
	}

	internals.getNodes();
	internals.setupListeners();
};

// EXPORTS

module.exports = {
	init: internals.init
};
