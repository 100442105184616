let internals = {};

function toggle(event) {
    var parent = this.parentElement;

    if (event.target.tagName !== 'A') {
        parent.classList.toggle('open');
    }
}

internals.init = function () {
    var toggleList = document.getElementsByClassName('toggle'),
    toggleList = Array.prototype.slice.call(toggleList),
    toggleListLength = toggleList.length;

	// If a toggle class doesn't exist on current page, don't run the JS
    if (toggleListLength === 0) {
    	return false;
	}

    for (var i = 0; i < toggleListLength; i++) {
        toggleList[i].firstElementChild.addEventListener('click', toggle);
    }
};

export default internals;
