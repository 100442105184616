import cookie from '../mjs-cookie/mjs-cookie.js';

let mjsYoutubeCheck = {
	init() {
		this.check();
	},
	check(){
		return new Promise((resolve, reject) => {
			const cookieName = 'mjs-youtube';
			let youtubeCookie = cookie.get(cookieName);

			// if youtubeCookie is empty that means the cookie hasn't been set yet.
			if (youtubeCookie === '') {
				const oneHour = 1 / 24; //as fraction of 1 day
				//request something from youtube.com, not a cdn, that will tell us if YouTube is blocked.
				let youTubeImage = new Image(),
					timestamp = new Date().getTime();
				youTubeImage.src = `//www.youtube.com/favicon.ico?${timestamp}`; // add timestamp to make sure we aren't getting a cached version
				youTubeImage.onload = function() {
					cookie.set(cookieName,true,oneHour);
					resolve('true');
				};
				youTubeImage.onerror = function() {
					cookie.set(cookieName,false,oneHour);
					resolve('false');
				}

				//check the cookie for any changes
				youtubeCookie = cookie.get(cookieName);
			} else {
				resolve(youtubeCookie === 'true');
			}
		})

	}
};

export default mjsYoutubeCheck;
