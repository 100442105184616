let mjsCookie = {

	set(name, val, days) {
		if (typeof(name) !== 'string' || name.trim() === '') {
			throw new Error('set() expects a string value for name');
		}

		let expires = '';

		if (days) {
			let date = new Date();
			date.setTime(date.getTime() + (days*24*60*60*1000));
			expires = '; expires=' + date.toGMTString();
		}

		document.cookie = name + '=' + val + expires + '; path=/';
	},

	get(name) {
		let nameEQ = name.trim() + '=',
			val = '',
			cookieArray = document.cookie.split(';');

		for(let i = 0, len = cookieArray.length; i < len; i++) {
			let c = cookieArray[i].trim();

			if (c.indexOf(nameEQ) === 0) {
				val = c.substring(nameEQ.length,c.length);
				//return immediately if found
				return encodeURIComponent(val);
			}
		}

		return encodeURIComponent(val);
	},

	remove(name){
		this.set(name, '', -1);
	}

};

export default mjsCookie;
