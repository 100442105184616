var internals = {};

internals.init = function(){
    if (document.getElementById('cite-this-page-link')){
        document.getElementById('cite-this-page-link').addEventListener('click', () => {
            
            // Show/hide the cite this page popup
            document.getElementById('cite-this-page').classList.toggle('visible');
            
            // Auto select the citation text when the popup is invoked
            const theCitation = document.getElementById('pageCitation');
            if(document.selection) { // Internet Explorer
                const selectedText = document.body.createTextRange();
                selectedText.moveToElementText(theCitation).select();
            }
            else {
                if(window.getSelection) {
                    const selectedText = document.createRange();
                    selectedText.selectNode(theCitation);
                    window.getSelection().removeAllRanges();
                    window.getSelection().addRange(selectedText);
                }
            }
        });
    }
};

module.exports.init = internals.init;