import arrayPoly from './components/arrayPoly/arrayPoly.js';

import {siteNav} from './components/nav/_nav.js';
import toggle from './components/toggle/_toggle.js';
import supports from './components/supports/_supports.js';
import primerOffscreenNav from './components/primer-offscreen-nav/_primer-offscreen-nav.js';
import banner from './components/banner/_banner.js';
import lightbox from './components/lightbox/_lightbox.js';
import people from './components/people/_people.js';
import mainHeader from './components/header/_header.js';
import subHeader from './components/sub-header/_sub-header.js';
import footnotes from './components/footnotes/_footnotes.js';
import photoGallery from './components/photo-gallery/_photo-gallery.js';
import searchResults from './components/search-results/_search-results.js';
import reader from './components/reader/_reader.js';
import events from './components/events/_events.js';
import citeThisPage from './components/cite-this-page/_cite-this-page.js';
import highlight from './highlight/highlight.js';
import languagePicker from './components/language-picker/_language-picker.js';
import navDropdown from './components/nav-dropdown/_nav-dropdown.js';

arrayPoly();

// Initialize video player on the page
var gallery = require('./components/video-gallery/_video-gallery.js');


window.SITE = window.SITE || {};
window.SITE.init = function() {

    siteNav();
    people.init(true);
    toggle.init();
    primerOffscreenNav.init();
    banner.init();
	mainHeader();
    subHeader();
    footnotes();
    photoGallery();
    searchResults.init();
    reader();
    events.init();
    citeThisPage.init();
    highlight.init();
	languagePicker();
	navDropdown();

    if (!document.querySelector('.lightbox')) {
        lightbox.init();
    }

    // I'm not where this block of code should go teaser or home.  I'm leaving it here for now.
    var mainFeature = document.getElementsByClassName('main-feature')[0];
    if (mainFeature) {
        if (!supports('object-fit')) {
            mainFeature.classList.add('no-object-fit');
        }
    }
	if(SITE.mediaList){ //jshint ignore:line
		gallery.init(SITE.mediaList, '.video-gallery'); //jshint ignore:line
	}
};

window.addEventListener('DOMContentLoaded', function () {
    window.SITE.init();
});
