import debounce from '../debounce/_debounce.js';

export default function languagePicker() {
	const languagePickers = document.querySelectorAll('.language-picker');

	// Fail fast if languagePicker does not exist
	if (!languagePickers.length) {
		return false;
	}

	const languagePickerDesktop = document.querySelector('[class*=header__main-row] > .language-picker, [class*=header__main-row] > div > .language-picker');

	const adjustPanelEdge = () => {
		let edgeDistance = document.body.clientWidth - languagePickerDesktop.getBoundingClientRect().right;

		edgeDistance = (150 - (edgeDistance < 150 ? edgeDistance : 150)) / 2;

		languagePickerDesktop.querySelector('.language-picker__content').style.setProperty('transform', `translateX(calc(-50% - ${edgeDistance}px))`);
	};

	Array.prototype.map.call(languagePickers, languagePicker => {
		const closeLanguagePicker = e => {
			if (!languagePicker.contains(e.target)) {
				languagePicker.classList.remove('active');
				document.body.removeEventListener('click', closeLanguagePicker);
			}
		};

		languagePicker.querySelector('.language-picker__trigger').addEventListener('click', e => {
			e.preventDefault();
			e.stopImmediatePropagation();
			languagePicker.classList.toggle('active');

			document.body[(languagePicker.classList.contains('active') ? 'add' : 'remove') + 'EventListener']('click', closeLanguagePicker);
		});
	});

	window.addEventListener('resize', debounce(adjustPanelEdge, 250));

	adjustPanelEdge();
}
