import {pageNav as buildPageNav} from '../nav/_nav.js';

var internals = {};

internals.preventScroll = function(event) {
    document.body.classList.add('stop-scrolling');
};

internals.allowScroll = function(event) {
    document.body.classList.remove('stop-scrolling');
};

export var internals = internals;

export default function() {
    // Make sure reader exists
    let reader = document.querySelector('.reader');
    if (reader !== null) {
        // Script for pageNav to run
        let body = document.body,
            pageNav = document.getElementById('reader-nav'),
            pageNavTop = pageNav.getBoundingClientRect().top,
            list = pageNav.querySelector('.list'),
            footer = document.querySelector('footer.footer'),
            mobile = window.matchMedia('(max-width: 900px)'),

            setUpPageNav = () => {
                let toggleScroll = function(event) {
                    if (pageNav.classList.contains('open')) {
                        internals.preventScroll();
                        document.addEventListener('touchmove', function(event) {
                            event.stopPropagation();
                        }, false);
                    } else {
                        internals.allowScroll();
                        document.removeEventListener('touchmove', function(event) {
                            event.stopPropagation();
                        }, false);
                    }
                };

                if (mobile.matches) {
                    pageNav.addEventListener('mouseenter', internals.preventScroll);
                    pageNav.addEventListener('mouseleave', internals.allowScroll);
                    pageNav.querySelector('.icon').addEventListener('click', toggleScroll);
                }
            };

		[...document.querySelectorAll('.more-info header, .for-teacher header')].forEach((el) => {
			el.addEventListener('click', (e)=>{
				el.parentNode.classList.toggle('open');
			});
		});

		[...document.querySelectorAll('.for-teacher')].forEach((el)=>{
			el.classList.add('open');
		});

        // Build readers PageNav
        buildPageNav({
            swipeFunctions: {
                right: function(event) {
                    document.getElementById('reader-nav').classList.add('open');
                    body.classList.add('stop-scrolling');
                    document.addEventListener('touchmove', function(event) {
                        event.stopPropagation();
                    }, false);
                },
                left: function(event) {
                    document.getElementById('reader-nav').classList.remove('open');
                    body.classList.remove('stop-scrolling');
                    document.removeEventListener('touchmove', function(event) {
                        event.stopPropagation();
                    }, false);
                }
            },
            callback: setUpPageNav
        });
    }
}

