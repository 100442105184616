export default function (selector, preCallBack) {
    let moveToTarget = () => {
        let target = document.getElementById(location.hash.slice(1));

        if (target) {
            let header =  document.querySelector('header'),
                publisherBar = document.querySelector('.publisher-bar'),
                headerHeight = 0;
            if (header){
                headerHeight += header.offsetHeight + header.offsetTop;
            }
            if (publisherBar){
                headerHeight += publisherBar.offsetHeight + publisherBar.offsetTop;
            }
            if (preCallBack){
                preCallBack(selector);
            }
            window.scrollTo(0, target.offsetTop - (headerHeight + 7));
        }
    };

    if (document.querySelector(selector)) {
        window.addEventListener('hashchange', moveToTarget);

        // Vanilla version of $(document).ready(...);
        document.onreadystatechange = function() {
            if (document.readyState === 'complete') {
                //Timeout needed to get scroll on document load
                setTimeout (function () {
                    moveToTarget();
                }, 100);
            }
        };
    }
}
