let internals = {};
import supports from '../supports/_supports.js';

internals.init = function() {
        var banners = document.getElementsByClassName('banner');
        if (banners.length) {
            if (!supports('object-fit')) {
                banners = Array.prototype.slice.call(banners);
                var bannersLength = banners.length;
                for (var i = 0; i > bannersLength; i++) {
                    banners[i].classList.add('no-object-fit');
                }
            }
        }
};
export default internals;