import delegate from '../../libs/event-delegator/event-delegator.js';

let internals = {};

internals.getStartByPage = function(pageNum, perpage, total){
	let newStart = (perpage * (pageNum-1));
	if (pageNum <= 0) {
		return 0;
	} else if (newStart >= total) {
		return (Math.floor( (total - 1) / perpage) * perpage);
	} else {
		return newStart;
	}
};

internals.init = function () {
	//Facets for search - selects (event listeners)
	delegate('.search-results', 'select', 'change', event => {
		let facetN = null;
        let facetV = event.target.value;
        let href = window.location.href;
            
		switch (event.target.name) {
			case 'results-per-page':
				facetN = 'perPage';
				break;
			case 'sort-by':
				facetN = 'sort';
				break;
		}

		//Replace the value
		let newUrl = href.replace(new RegExp('&?' + facetN + '=[^\s&]*', 'gi'), '') + '&' + facetN + '=' + facetV;
		window.location.href = newUrl;
	});

	//For the 'Go to page' input box
	delegate('.pagination', '#page-num', 'keypress', (event) => {
		let key = event.which || event.keyCode;
		// 13 is enter
		if (key === 13) { 
			let perpage = parseInt(event._target.dataset.perpage),
				total = parseInt(event._target.dataset.total),
				pageTarget = parseInt(event._target.value),
				newUrl = window.location.href.replace(/&?start=[^&]*/gi, '');
			newUrl += '&start=' + internals.getStartByPage(pageTarget, perpage, total);
			if (newUrl.indexOf('perpage=') === -1) {
				newUrl += '&perpage=' + perpage;
			}
			window.location.href = newUrl;
		}
	}); 
};

export default internals;
