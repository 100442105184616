var HEADER_HEIGHT = 55;
let highlighter = {
    init() {
        var isSearchParam = /(\?|&)highlight/.test(window.location.search);
        var mark = document.querySelector('mark'); //find first occurance of mark element
        var footnotes = document.querySelector('.footnotes');
        if (!isSearchParam || !mark) {
            return false;
        } else if(footnotes && footnotes.contains(mark)){
            footnotes.classList.add('open');
        }
        setTimeout(function() {
            mark.scrollIntoView();
            if (window.scrollBy) {
                window.scrollBy(0, HEADER_HEIGHT * -1);
            }
        }, 500);
    },
    // http://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript
    escape(s) {
        return s ? s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
    },
    /**
     * highlight
     * @param {string} html - html content to be highlighted.
     * @param {string} query - the string content to highlight in the html.
     * @return {string} - the modified highlighted html.
     */
    highlight(html, query) {
        var escapedQuery = highlighter.escape(query);
        if (!escapedQuery) {
            return html
        }
        var modifiedHtml = html;
        var terms = escapedQuery.split('\\|');
        terms.forEach(function (term) {
            var regexp = RegExp('>.*' + term + '.*<', 'ig');
            modifiedHtml = modifiedHtml.replace(regexp, function (match) {
                return match.replace(new RegExp(term, 'ig'), '<mark>$&</mark>');
            });
        });
        return modifiedHtml;
    }
}
export default highlighter;