import debounce from '../debounce/_debounce.js';
import youtubeCheck from '../../libs/mjs-youtube-check/mjs-youtube-check.js';

$.bbq = {};
$.bbq.getState = function () {
    return false;
};

var isScreenWidthIsTabletOrSmaller = function(width) {
    return (width < 900);
};

youtubeCheck.init();

var init = function (list, selector) {
    var loadId = window.location.hash.indexOf('watch') > -1 ? window.location.hash.split('=')[1] : undefined,
        savedScreenWidthIsTabletOrSmaller; // Used as a history, to see if mediaQuery will need to change
    //abort function if selector doesn't exist on the page
    if (!$(selector).length) {
        return false;
    }
    
    function attachVideoPlayer(activeTeaser, videoPlayer) {
        var sizeName = '';
        var teasers = [].reverse.call(activeTeaser.prevAll('.video-teaser'));
        // Get the Current screen size and choose where to create the video player -Brendan H.
        
        if (isScreenWidthIsTabletOrSmaller(window.innerWidth)) {
            savedScreenWidthIsTabletOrSmaller = true;
            if (teasers.length % 2 === 0) {
                //insert videoPlayer in correct position when the number of previous teasers is even.
                activeTeaser.before(videoPlayer);

            } else {
                //insert heml when number of previous teasers is odd.
                activeTeaser.prev().before(videoPlayer);
            }
        } else {
            savedScreenWidthIsTabletOrSmaller = false;
            var targetItemIndex = Math.floor((teasers.length) / 4) * 4;
            if (targetItemIndex === teasers.length) {
                activeTeaser.before(videoPlayer);

            } else {
                $(teasers[targetItemIndex]).before(videoPlayer);
            }
        }
    }
                                   
    function buildPlayerModal(event, preventAutoStart) {
        //remove current active video and class
        $('.video-teaser.active').removeClass('active');
        $('.video-player').remove();

        //get the id from the url
        var id = $(this).attr('href').split('=')[1];
        //build the  from the data and the partial
        var html = Handlebars.partials['_video-player'](list[id]);
        $(this).parents('.video-teaser').addClass('active');
        // Store active teaser
        var activeTeaser = $(this).parents('.active');
        
        attachVideoPlayer(activeTeaser, html);

        if (preventAutoStart !== true){
            list[id].params.autoStart = true;
        }
        
        //load medialoader on player element
        const player = document.querySelector('#video-player__player');
        if(!player) {
            return;
        }
        window.mediaLoader('#video-player__player', {
            mediaList: list,
            useHash: false,
            youTubeCheck: youtubeCheck.check(),
            playerOptions: {
                'wmode': 'transparent',
                'isVid': 'true',
                'isUI': 'true',
                'dynamicStreaming': 'true',
                'bgcolor': '#fff',
                'width': 470,
                'height': 264
            }
        });
        player.mediaLoader('open', id);
        list[id].params.autoStart = false;
    }

    function standAloneInit() {
        $('.video-teaser.active').removeClass('active');
        $('.video-player').remove();
        //get the id from the url
        var id = $(this).attr('href').split('=')[1];
        
        //load medialoader on player element
        window.mediaLoader(this, {
            mediaList: list,
            useHash: false,
            playerOptions: {
                'wmode': 'transparent',
                'isVid': 'true',
                'isUI': 'true',
                'dynamicStreaming': 'true',
                'bgcolor': '#fff',
                'width': 470,
                'height': 264
            }
        });

        this.mediaLoader('open', id);
    }
    
    function reBuild() {
        // Check first to see if the mediaQuery will even need to change; If not, don't rebuild
        if (isScreenWidthIsTabletOrSmaller(window.innerWidth) !== savedScreenWidthIsTabletOrSmaller) {
            buildPlayerModal.call(document.querySelector('.video-teaser.active a'), null, true);
        }
    }
    
    
    function loadDefault(){
        //load the video - either from hash or the first teaser
        let vidId = loadId;
        if (!vidId && list) {
            vidId = Object.keys(list)[0];
        }
        let target = $('.video-teaser a[href="#watch=' + vidId + '"]');
        if (target.length) {
            target.trigger('click', [true]);
        }
    }

    //handle video play click
    $('.video-teaser__video a:not(".stand-alone")').on('click', buildPlayerModal);
    $('.video-teaser__video a.stand-alone').on('click', standAloneInit);

    loadDefault();
    window.addEventListener('resize', debounce(reBuild, 250));
    
};

module.exports.init = init;