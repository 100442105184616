import debounce from '../debounce/_debounce.js';

export default function navDropdown() {
	const navDropdowns = document.querySelectorAll('.nav-dropdown');

	// Fail fast if navDropdown does not exist
	if (!navDropdowns.length) {
		return false;
	}

	const navDropdownDesktop = document.querySelector('[class*=header__main-row] > .nav-dropdown, [class*=header__main-row] > div #site-nav .nav-dropdown');

	const adjustPanelEdge = () => {
		let edgeDistance = document.body.clientWidth - navDropdownDesktop.getBoundingClientRect().right;

		edgeDistance = (150 - (edgeDistance < 150 ? edgeDistance : 150)) / 2;

		navDropdownDesktop.querySelector('.nav-dropdown__content').style.setProperty('transform', document.body.clientWidth > 900 ? `translateX(calc(-50% - ${edgeDistance}px))` : "");
	};

	Array.prototype.map.call(navDropdowns, navDropdown => {
		const closeNavDropdown = e => {
			if (!navDropdown.contains(e.target)) {
				navDropdown.classList.remove('active');
				document.body.removeEventListener('click', closeNavDropdown);
			}
		};

		navDropdown.querySelector('.nav-dropdown__trigger').addEventListener('click', e => {
			e.preventDefault();
			e.stopImmediatePropagation();
			navDropdown.classList.toggle('active');

			document.body[(navDropdown.classList.contains('active') ? 'add' : 'remove') + 'EventListener']('click', closeNavDropdown);
		});
	});

	window.addEventListener('resize', debounce(adjustPanelEdge, 250));

	adjustPanelEdge();
}
