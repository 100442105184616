import debounce from '../debounce/_debounce.js';

var internals = {},
    oldMedia;

internals.init = function(force){
	var people = document.querySelector('.people');

    if (people) {
        let select = document.getElementById('sort-by'),
            peopleList = people.querySelector('.people__list ul'),
            totalHeight = peopleList.offsetHeight,
        buildColumns = () => {
            let media = window.matchMedia('(min-width: 641px)').matches + window.matchMedia('(min-width: 901px)').matches;
            if (peopleList) {
                let child = peopleList.querySelector('li');
                if (child) {
                    let childHeight = child.offsetHeight;
                    if (media !== oldMedia || force) {
                        oldMedia = media;
                        // mobile = 0; tablet = 1; dekstop = 2;
                        if (media === 0) {
                            peopleList.style.height = 'auto';
                        } else {
                            let equalCols = totalHeight / (media + 1),
                            colHeight = Math.ceil(equalCols / childHeight) * childHeight / 10;
                            peopleList.style.height = colHeight + 'rem';
                        }
                    }
                } 
            }
        };
    
        if (select) {
            select.addEventListener('change', function(){
                var url = '?sort=' + this.value;
                window.location.href = url;
            });
        }

        buildColumns();
        window.addEventListener('resize', debounce(buildColumns, 250));
    }
};

module.exports.init = internals.init;