import swipe from '../swipe/_swipe.js';

/*****************
 * Global Functions
 *****************/

function removeClass(nav, className) {
    var elements = [].slice.call(nav.getElementsByClassName(className));
    for (let i = 0, length = elements.length; i < length; i++) {
        elements[i].classList.remove(className);
    }
}

/*****************
 * Site Nav
 *****************/
 export var siteNav = function(){
    var offNav = document,
        nav = document.querySelector('#site-nav.toggle');
    if (nav && offNav){
        //Listen to the whole body
        offNav.addEventListener('click', function(event){
            //Not the menu icon
            if (!event.target.classList.contains('icon__graphic--menu')) {
                //Close menu if open
                if (nav.classList.contains('open')) {
                    nav.classList.remove('open');
                }
            }
        });
    }
 };

/*****************
 * Page Nav
 *****************/
export var pageNav = function ({
    swipeFunctions = {},
        callback
} = {}) {
    let pageNav = document.querySelector('#reader-nav'),

        openParents = (el) => {
            // Get the parent li of current element
            let parent = el.parentElement.parentElement;

            // Make sure we were in a nested list
            if (parent.tagName !== 'LI') {
                return;
            }

            // Add selected to parent
            parent.classList.add('open');

            openParents(parent);

            return;
        },

        changeSelection = (event) => {
            var el = event.target,
                elName = el.tagName;

            // Make sure that the element being modified is an li tag
            if (elName === 'A') {
                // For emulating Historify
                // event.preventDefault();
                // event.stopPropagation();
                el = el.parentElement.parentElement;
            } else {
                return;
            }

            removeClass(pageNav, 'selected');
            removeClass(pageNav, 'open');

            // Add selected to current element
            el.classList.add('selected');

            // Add open if element has toggle
            if (el.classList.contains('toggle')) {
                el.classList.add('open');
            }

            openParents(el);
        };

    if (pageNav !== null) {
        pageNav.addEventListener('click', changeSelection);
        swipe(swipeFunctions);
        callback();
    }
};