import matches from '../matches/matches.js';

export default (parentSelector, childSelector, event, callback) => {
    [...document.querySelectorAll(parentSelector)].forEach((parent) => {
        parent.addEventListener(event, (e) => {
            let target = e.target;
            while(!matches(target, parentSelector) && !matches(target,'html')){
                if(matches(target,childSelector)){
                    e._target = target;
                    callback.call(this, e);
                    break;
                }
                target = target.parentNode;
            }
        });
    });
};